// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[1]!../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[2]!../node_modules/resolve-url-loader/index.js??ruleSet[1].rules[1].oneOf[7].use[3]!../node_modules/sass-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[4]!../node_modules/animate.css/animate.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Condiment&family=Outfit&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://use.typekit.net/rzn6lae.css);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "body {\n  font-family: \"Source Sans\", sans-serif;\n}", "",{"version":3,"sources":["webpack://./src/App.scss"],"names":[],"mappings":"AAQA;EACI,sCAAA;AAJJ","sourcesContent":["$primary-color: #ff0067;\n@import 'animate.css';\n\n@import url('https://fonts.googleapis.com/css2?family=Condiment&family=Outfit&display=swap');\n$outfit: Outfit, sans-serif;\n\n@import url(\"https://use.typekit.net/rzn6lae.css\");\n\nbody {\n    font-family: 'Source Sans', sans-serif;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
